<template>
  <div class="member">
    <van-nav-bar
      left-arrow
      @click-left="onClickLeft"
      fixed
    >
      <template #title>
        <form action="/">
          <van-search
            v-model="keywords"
            show-action
            shape="round"
            placeholder="请搜索邀请码"
            @search="onSearch"
            @cancel="onCancel"
          >
            <template #left-icon>
              <div style="width:130px;display:flex;">
                <van-dropdown-menu class="selected">
                  <van-dropdown-item v-model="active" :options="option1" />
                </van-dropdown-menu>
                <van-dropdown-menu class="selected">
                  <van-dropdown-item v-model="levelsearch" :options="option2" />
                </van-dropdown-menu>
              </div>
            </template>
            <template #action>
              <div @click="onSearch">搜索</div>
            </template>
          </van-search>
        </form>
      </template>
    </van-nav-bar>
    <div class="list_main">
      <van-pull-refresh style="min-height: 100vh;" v-model="isRefresh" success-text="刷新完成" @refresh="onRefresh">
        <van-list
          v-model="noloading"
          :finished="nofinished"
          :finished-text="finishedText"
          @load="onLoad"
        >
          <div v-if="!isnodata">
            <van-cell center v-for="(item, index) in list" :key="index">
              <template #title>
                <div class="van-cell__img">
                  <van-image
                    :src="item.avatar"
                    width="60"
                    height="60"
                    round
                    fit="cover"
                    lazy-load
                  >
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                  <div class="list_fen_tag">
                    <van-tag
                      size="medium"
                      color="pink"
                      round
                      v-if="item.lock_shop >= 0 && item.lock_shop == info.id"
                    >粉</van-tag>
                    <van-tag
                      size="medium"
                      color="#74baed"
                      v-if="item.lock_shop == -1"
                      round
                    >未</van-tag>
                    <van-tag
                      size="medium"
                      color="#000000"
                      v-if="item.lock_shop >= 0 && item.lock_shop != info.id"
                      round
                    >禁</van-tag>
                  </div>
                </div>
              </template>
              <template #default>
                <div class="list_title">
                  <div class="van-ellipsis list_title_text">{{item.nickname}}</div>
                  <div class="list_title_tag">
                    <van-tag v-if="item.level != 1" class="tag_agent" round color="#D6B594" text-color="#000">{{item.levelname}}</van-tag>
                    <van-tag v-if="item.level == 1 && item.dingtype == 0" class="tag_agent" round color="#D6B594" text-color="#000">{{item.levelname}}</van-tag>
                    <van-image v-if="item.level == 1 && item.dingtype == 1" width="3.5rem" fit="contain" src="https://img.midbest.cn/image/xcx_img/ding_v.png" />
                    <van-image v-if="item.level == 1 && item.dingtype == 2" width="3.5rem" fit="contain" src="https://img.midbest.cn/image/xcx_img/ding_yue.png" />
                    <van-image v-if="item.level == 1 && item.dingtype == 3" width="3.5rem" fit="contain" src="https://img.midbest.cn/image/xcx_img/ding_shi.png" />
                  </div>
                </div>
                <div class="level_list">
                  <!-- <van-tag :color="item.check_level == 0 ? '#7A5228' : '#EA5616'" text-color="#fff">{{item.check_level == 0 ? '顶级免考核' : '顶级需考核'}}</van-tag> -->
                  <van-tag :color="item.check_color" text-color="#fff">{{item.check_desc}}</van-tag>
                  <van-tag class="van-ellipsis tag_agent" color="#E95515" @click="copayAgentCode(item.agent.agent_code)" v-if="item.agent && item.agent.id > 0">邀请人:{{item.agent.agent_code}}</van-tag>
                    <van-tag class="van-ellipsis tag_agent" v-else >邀请人:无</van-tag>
                  <van-tag
                    v-if="false"
                    :type="item.teji_white == 1 ? 'primary' : ''"
                    :plain="item.teji_white == 1 ? false : true"
                    :color="item.teji_white == 0 ? '#969799' : ''"
                    @click="changeTeji(index)"
                  >
                    特级免考核
                  </van-tag>
                </div>
                <div class="level_list" @click="copayAgentCode(item.agent_code)">
                  邀请码：{{item.agent_code}}
                </div>
              </template>
              <template #right-icon>
                <div>
                  <div style="text-align: right;">
                    <van-button
                      type="danger"
                      size="mini"
                      plain
                      round
                      @click="showLvelList(index)"
                    >
                      设置
                    </van-button>
                  </div>
                  <div style="margin-top: 10px;">
                    <van-button
                      v-if="(!item.agent || item.agent.id <= 0) && item.lock_shop >= 0 && item.lock_shop == info.id"
                      type="danger"
                      size="mini"
                      plain
                      round
                      @click="changAgent(index)"
                    >
                      添加推荐人
                    </van-button>
                  </div>
                </div>
              </template>
            </van-cell>
          </div>
          <van-empty v-if="isnodata" description="暂无数据" />
        </van-list>
      </van-pull-refresh>
    </div>
    <van-action-sheet
      v-model="levelshow"
      :actions="actions"
      cancel-text="取消"
      title="选择等级"
      close-on-click-action
      @cancel="onLevelCancel"
      @select="onLevelSelect"
      get-container=".member"
    />
    <!-- 设置不考核弹框 -->
    <van-dialog
      v-model="showsetkaohe"
      :title="setKaoheTitle" 
      show-cancel-button
      :before-close="doCheckLevel"
    >
      <div class="checeLevel">
        <van-field
          v-model="checkLevelFrom.nickname"
          label="用户昵称："
          :border="false"
          readonly
        />
        <van-field
          v-model="checkLevelFrom.money"
          label="收款金额："
        />
        <van-field
          label="收款凭证："
          :border="false"
        >
          <template #input>
            <van-uploader v-model="fileList" :max-count="1" :after-read="afterRead" />
          </template>
        </van-field>
      </div>
    </van-dialog>
    <van-dialog
      v-model="showchangeagent"
      title="修改推荐人" 
      show-cancel-button
      :before-close="doChangeAgent"
    >
      <div class="checeLevel">
        <van-field
          v-model="changeagentcode"
          label-width="90px"
          label="推荐人邀请码:"
        />
      </div>
    </van-dialog>
  </div>
</template>
<script>
import { Toast } from 'vant';
export default {
  name: "Member",
  data() {
    return {
      screenWidth: document.body.clientWidth, // 屏幕宽
      screeHeight: document.body.clientHeight, // 屏幕高
      list: [], // 数据列表
      loading: false,
      finished: false,
      active: 0,
      levelsearch: 0,
      memberList: [],
      currOffset: 0,
      currSize: 20,
      keywords: "", // 搜索关键词
      nodata: false, // 是否显示空数据
      finishedText: "",
      levelshow: false, // 默认隐藏等级选择弹框
      actions: [
        { name: '顶级免考核', level: -2, className: "levelTan_list" },
        { name: '特级免考核', level: -1, className: "levelTan_list" },
        { name: '顶级会员', level: 1, className: "levelTan_list" },
        { name: '特级会员', level: 6, className: "levelTan_list" },
        { name: '普通会员', level: 0, className: "levelTan_list" }
      ],
      member: {}, // 已选用户
      index: -1, // 已选用户索引
      isLoading: false,
      setTeji: false, // 是否设置中
      info: {},
      showsetkaohe: false, // 设置是否考核弹框默认隐藏
      fileList: [], // 图片上传预览
      checkLevelFrom: {
        index: -1,
        nickname: "",
        memberid: 0,
        money: "",
        'money_img': "",
        "check_level": 1,
        level: 0
      },
      setKaoheTitle: "",
      isRefresh: false, 
      noloading: false,
      nofinished: false,
      isnodata: false,
      option1: [
        { text: '类型', value: 0 },
        { text: '直属', value: 1 },
        { text: '间接', value: 2 }
      ],
      option2: [
        { text: '等级', value: 0 },
        { text: '顶级', value: 1 },
        { text: '特级', value: 2 },
        { text: '普通', value: 3 }
      ],
      showchangeagent: false,
      changememberid: 0,
      changeagentcode: '',
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.getinfo();
    },
    getinfo() {
      let that = null;
      that = this;
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(that.$store.state.domain + "shopsmobile/user/get_info")
        .then(res => {
          console.log(res);
          if (res.data.code == 100000) {
            that.info = res.data.data;
            console.log(that.info);
          }
        })
        .catch(err => {
          console.log(err);
        })
    },
    onRefresh() {
      this.currOffset = 0;
      this.isnodata = this.nodata = false;
      this.getinfo();
      this.onLoad();
    },


    onLoad() {
      console.log("sss");
      let that = null;
      that = this;
      that.$toast({
        message: '加载中...',
        duration: 0,
        forbidClick: true,
        overlay: true
      })
      that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
      that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
      that.$axios
        .post(
          that.$store.state.domain + "shopsmobile/member/member_list",
          that.$qs.stringify({
            active: that.active,
            levelsearch: that.levelsearch,
            offset: that.currOffset,
            size: that.currSize,
            keywords: that.keywords,
          })
        )
        .then(res => {
          console.log(res);
          that.$toast.clear();
          if (that.keywords) {
            // that.active = res.data.active;
            that.memberList = [];
          }
          that.loading = false;
          that.isLoading = false;
          if (res.data.code == 100000) {
            that.nodata = false;
            if (that.currOffset == 0) {
              that.memberList = res.data.data;
            }else {
              res.data.data.forEach(item => {
                that.memberList.push(item);
              })
            }
            that.list = that.memberList;
            if (res.data.data.length >= that.currSize) {
              that.currOffset += that.currSize;
              that.finished = false;
            }else {
              that.currOffset += res.data.data.length;
              that.finished = true;
              that.finishedText = "没有更多了";
            }
          }else {
            
            that.finished = true;
            if (that.keywords && that.keywords.length > 0) {
              that.nodata = true;
              that.finishedText = "";
            }else {
              if(that.currOffset == 0) {
                that.nodata = true;
                that.finishedText = "";
              }else{
                that.finishedText = "没有更多了";
              }
            }
          }
          console.log(that.active, that.isLoading, that.loading, that.nodata, "dddd");

          // 各个级别会员的数量显示到页面
          that.option2.forEach((_pLv) => {
            (res.data.level_info).forEach((_apiLv) => {
              if(_pLv.value == _apiLv.level_key){
                _pLv.text = _apiLv.level_name + ':' + _apiLv.t_num;
              }
            });
          });

          that.isRefresh = that.isLoading, 
          that.noloading = that.loading,
          that.nofinished = that.finished,
          that.isnodata = that.nodata;
        })
        .catch(err => {
          console.log(err);
        })
    },
    // 执行搜索
    onSearch() {
      console.log(this.keywords);
      this.currOffset = 0;
      this.onLoad();
    },
    // 取消搜索
    onCancel() {
      console.log(this.keywords);
      this.currOffset = 0;
      this.finished = false;
      this.nodata = false;
      this.memberList = [];
    },
    // 返回上一页
    onClickLeft() {
      this.$router.back();
    },
    // 显示设置等级弹框
    showLvelList(index) {
      this.member = this.list[index];
      this.index = index;
      this.levelshow = true;
      
      console.log(index);
    },
    // 取消选择等级
    onLevelCancel() {
      console.log("取消选择等级");
    },
    onLevelSelect(item) {
      console.log("选择等级", item);
      if (parseInt(this.info.is_kaohe) == 0) {
        if (this.member.level == 1) {
          if (item.level != -2) {
            this.$toast("无法修改，不可降级");
            return false;
          }
        }else if(this.member.level == 6) {
          if (item.level == 0) {
            this.$toast("无法修改，不可降级");
            return false;
          }
        }
      }

      console.log(this.member);
      if (this.member.hehuoren && this.member.hehuoren >= 0) {
        this.setLevel(item);
      }else if (parseInt(this.member.level) !== 1 && item.level == 1 && this.member.check_level == 0) {
        this.checkLevelFrom = {
          index: this.index,
          nickname: this.member.nickname,
          memberid: this.member.id,
          money: "",
          'money_img': "",
          "check_level": 1,
          level: item.level,
          name: item.name
        }
        this.setKaoheTitle = "升为顶级";
        this.showsetkaohe = true;
      }else {
        this.setLevel(item);
      }
    },
    // 执行修改等级操作
    setLevel(item) {
      // 执行修改等级操作
      let that = null;
      that = this;
      let name = '';
      if (this.member.nickname) {
        name = this.member.nickname;
      }else {
        name = '未设置昵称';
      }
      let levelnamestr = '';
      levelnamestr = item.name ? item.name : "";
      console.log(levelnamestr, item, "item");

      if(item.level == -2) { // 顶级免考核 需要上传收费截图
        this.checkLevelFrom = {
          index: this.index,
          nickname: this.member.nickname,
          memberid: this.member.id,
          money: "",
          'money_img': "",
          "check_level": 1,
          level: 1,
          name: item.name
        }
        this.setKaoheTitle = item.name;
        this.showsetkaohe = true;
        return false;
      }

      if (item.level == -1) {
        this.changeTeji(this.index);
        return false;
      }

      that.$dialog.confirm({
        title: '确认更改等级',
        message: '确认要将【'+name+'】设置为'+levelnamestr+'？',
      })
      .then(() => {
        // on confirm
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
          .post(
            that.$store.state.domain + "shopsmobile/member/set_level",
            that.$qs.stringify({
              level: item.level,
              memberid: that.member.id
            })
          )
          .then(res => {
            console.log(res);
            if (res.data.code == 100000) {
              that.$toast(res.data.msg ? res.data.msg : "修改成功");
              // that.list[that.index].levelname = item.name;
              // that.list[that.index].level = item.level;
              setTimeout(() => {
                that.onRefresh();
              }, 1500);
            }else {
              that.$toast(res.data.msg ? res.data.msg : "修改失败");
            }
          })
          .catch(err => {
            console.log(err);
          })
      })
      .catch(() => {
        // on cancel
      });
        
    },
    // 复制邀请码
    copayAgentCode(code) {
      let _that = null;
      _that = this;
      _that.$copyText(code)
      .then(
        function(e) {
          _that.$toast("邀请码复制成功");
          console.log(e);
        },
        function(e) {
          console.log(e);
        }
      );
    },
    // 特级白名单
    changeTeji(e) {
      console.log(e);
      let that = null;
      that = this;
      let id = 0;
      let status = 0;
      id = that.list[e].id;
      status = that.list[e].teji_white;
      if (!that.setTeji) {
        that.setTeji = true;
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
          .post(
            that.$store.state.domain + "shopsmobile/member/set_teji_white",
            that.$qs.stringify({
              id: id,
              status: status,
              level: that.list[e].level
            })
          )
          .then(res => {
            console.log(res);
            that.setTeji = false;
            if (res.data.code == 100000) {
              that.$toast(res.data.msg ? res.data.msg : "设置成功");
              // that.list[e]['teji_white'] = 1-status;
              setTimeout(() => {
                that.onRefresh();
              }, 1500);
            }else {
              that.$toast(res.data.msg ? res.data.msg : "设置失败");
            }
          })
          .catch(err => {
            console.log(err);
            that.setTeji = false;
          })
      }
    },
    // 设置是否考核
    showCheckLevel(index) {
      let that = null;
      that = this;
      this.fileList = [];
      that.checkLevelFrom = {
        index: index,
        memberid: that.list[index].id,
        nickname: that.list[index].nickname,
        money: "",
        'money_img': "",
        'check_level': that.list[index].check_level,
        level: that.list[index].level,
      }
      
      if (that.list[index].check_level == 1 && that.list[index].level == 1) {
        that.setKaoheTitle = "设为免审核";
        that.showsetkaohe = true;
      }else {
        let title = "";
        title = "是否要调整为不考核业绩";
        if (that.list[index].check_level == 0) {
          title = "是否要重新调整为考核业绩";
        }
        that.$dialog.confirm({
          title: title,
          showCancelButton: true,
          beforeClose: (action, done) => {
            console.log(action);
            if (action == 'confirm') {
              that.doCheckLevel(action, done);
            }
            done();
          }
        })
      }
    },
    // 上传图片
    afterRead(file) {
      let that = null;
      that = this;
      console.log(file);
      const fordata = new FormData();
      fordata.append('file',file.file);
      // fordata.append('name',file.file.name);
      fordata.append('dir','image/shopkaohe/zhuanzhang');
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 是否禁止背景点击
        message: '上传中...',
        overlay: true, // 是否显示背景遮罩层
      });
      that.$axios.defaults.headers["Content-Type"] = "multipart/form-data";
      that.$axios.post(
        that.$store.state.domain + "admin/image/shangchuan",
        fordata
      ).then( response =>{
        console.log(response);
        Toast.clear();
        if (response.data && response.data.code == 100000) {
          that.checkLevelFrom['money_img'] = response.data.img_url;
          Toast("上传完成")
        }
      }).catch( error =>{
        console.log(error);
      })
    },
    // 执行考核
    doCheckLevel(action, done) {
      let that = null;
      that = this;
      
      if(action == 'confirm'){
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
          .post(
            that.$store.state.domain + "shopsmobile/member/set_check_level",
            that.$qs.stringify(that.checkLevelFrom)
          )
          .then(res => {
            console.log(res);
            that.setTeji = false;
            if (res.data.code == 100000) {
              // if (that.checkLevelFrom.name) {
              //   // that.setLevel(that.checkLevelFrom);
              // }else{
              //   that.$toast(res.data.msg ? res.data.msg : "设置成功");
              // }
              // console.log(that.checkLevelFrom);
              that.$toast(res.data.msg ? res.data.msg : "设置成功");
              // if (that.checkLevelFrom.check_level==1) {
              //   that.list[that.checkLevelFrom.index]['check_level'] = 0;
              // }else{
              //   that.list[that.checkLevelFrom.index]['check_level'] = 1;
              // }
              setTimeout(() => {
                that.onRefresh();
              }, 1500);
              done();
            }else {
              that.$toast(res.data.msg ? res.data.msg : "设置失败");
              done(false);
            }
          })
          .catch(err => {
            console.log(err);
            // that.setTeji = false;
            done(false);
          })
      }else {
        done();
      }
    },
    // 修改推荐人
    changAgent(index) {
      let _that = null;
      _that = this;
      _that.changememberid = _that.list[index].id;
      _that.changeagentcode = '';
      _that.showchangeagent = true;
    },
    // 更改推荐人
    doChangeAgent(action, done) {
      let that = null;
      that = this;
      
      if(action == 'confirm'){
        that.$axios.defaults.headers.common['Authorization'] = localStorage.getItem("SHOP_UID");
        that.$axios.defaults.headers["Content-Type"] =  "application/x-www-form-urlencoded";
        that.$axios
          .post(
            that.$store.state.domain + "shopsmobile/member/change_agent",
            that.$qs.stringify({
              memberid: that.changememberid,
              agentcode: that.changeagentcode
            })
          )
          .then(res => {
            console.log(res);
            that.setTeji = false;
            if (res.data.code == 100000) {
              that.$toast(res.data.msg ? res.data.msg : "设置成功");
              setTimeout(() => {
                that.onRefresh();
              }, 1500);
              done();
            }else {
              that.$toast(res.data.msg ? res.data.msg : "设置失败");
              done(false);
            }
          })
          .catch(err => {
            console.log(err);
            // that.setTeji = false;
            done(false);
          })
      }else {
        done();
      }
    },
  },
}
</script>
<style lang="less">
.member {
  width: 100%;
  min-height: 100%;
  background-color: #f0f0f0;
  &:last-child {
    padding-bottom: 50px;
  }
  // 头部样式
  .van-nav-bar {
    .van-nav-bar__title {
      max-width: 100%;
      flex: 1;
      .van-search {
        padding: 10px 0;
        .van-search__action {
          padding: 0 16px;
        }
        .van-cell__value {
          flex: 1;
        }
      }
    }
    .van-nav-bar__left {
      position: static;
    }
    .selected {
      .van-dropdown-menu__bar {
        height: 24px;
        box-shadow: none;
        padding-right: 10px;
        background-color: #F7F8FA;
        .van-dropdown-menu__item {
          background-color: #F7F8FA;
          .van-dropdown-menu__title {
            font-size: 12px;
            color: #999;
          }
        }
      }
      .van-dropdown-item--down .van-cell {
        padding: 5px 16px;
        .van-cell__value .van-icon {
          color: #ee0a24;
        }
      }
    }
  }
  .list_main {
    width: 100%;
    overflow: hidden;
    padding-top: 50px;
    .van-cell {
      .van-cell__title {
        padding: 0 10px 0 0;
        overflow: hidden;
        position: relative;
        .van-image {
          display: block;
        }
        .list_fen_tag {
          .van-tag {
            margin-right: 5px;
            padding: 2px 4px;
            position: absolute;
            right: 5px;
            bottom: 0;
            &:last-of-type {
              margin-right: 0;
            }
          }
        }
      }
      .van-cell__value {
        flex: 1;
        text-align: left;
        .list_title {
          display: flex;
          overflow: hidden;
          .list_title_text {
            overflow: hidden;
            color: #000;
          }
          .list_title_tag {
            min-width: 118px;
            flex: 1;
            margin: 0 10px;
            display: flex;
            align-items: center;
            .tag_agent {
              padding: 0 8px;
              height: 20px;
              line-height: 20px;
            }
          }
        }
        .level_list {
          margin-top: 5px;
          display: flex;
          justify-items: center;
          line-height: 18px;
          .van-tag {
            padding: 1px 5px;
            margin-right: 5px;
            &:last-of-type {
              margin-right: 0px;
            }
          }
        }
      }
      .van-button {
        padding: 0 10px;
        font-size: 13px;
      }
    }
  }

  .van-action-sheet__header {
    color: #999;
    padding-bottom: 20px;
  }
  .van-action-sheet__content {
    padding: 0 10px;
    .levelTan_list {
      border-bottom: 1px solid #eee;
      &:last-of-type {
        border-bottom: 0;
      }
    }
  }
  
}
</style>